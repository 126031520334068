
    import {
        defineComponent,
        reactive,
        onMounted,
        ref,
        watch,
        nextTick,
        inject,
        onUpdated
    } from "vue";

    //  components
    import Loading from "@/components/UI/Loading";
    import Recommend, { RecommnedList } from "@/components/common/Recommend.vue";
    // import Star from "@/components/common/Star.vue";
    // import Comment from "@/components/common/Comment.vue";
    import Preview from "@/components/UI/Preview";
    import { ImgItem } from "@/components/UI/Preview/src/Preview.vue";
    import { useRoute, useRouter } from "vue-router";
    import $, { param } from "jquery";
    import axios from "@/api/axios";
    import dateFormat from "@/utils/dateFormat";
    import qs from "qs";
    import Toast from "@/components/UI/Toast";
    import PublishComment from "@/components/common/PublishComment.vue";
    import asyncLoadJs from "@/hooks/useAsyncLoadJs";
    import Modal from "@/components/UI/Modal";
    import wx from "weixin-sdk-js";

    export default defineComponent({
        name: "about",
        components: {
            Recommend,
            Loading,
            // Star,
            // Comment,
            Preview,
            PublishComment
        },

        setup() {
            //  get page params
            const shareTitle = ref('')
            const route = useRoute();
            const router = useRouter();
            const id = ref(route.query.id);
            const news = ref(null);
            //  recommend
            const recommendList = reactive<RecommnedList[]>([]);
            const likeCount = ref(0);
            const likeUserCount = ref(0);
            const likeUser = ref<object>([]);
            //  comment
            const listComment = ref<object>([]);
            const totalCount = ref(0);
            const loading = ref(true);
            const previous = ref(null);
            const next = ref(null);

            function getRenderData(id: string) {
                //  async data
                news.value = null;
                loading.value = true;
                recommendList.length = 0;
                axios
                    .get("/M/News/ActivityDetail/" + id)
                    .then(res => {
                        if (res.data.success) {
                            const data = res.data.obj;
                            news.value = data;
                            shareTitle.value = data.activityTitle
                            document.title = data.activityTitle + "-北纳生物";
                            loading.value = false;
                            route.meta.title = data.activityTitle;
                            route.meta.content.keywords = data.keyword;
                            route.meta.content.description = data.activitySummary;
                            loading.value = false;
                            asyncLoadJs(
                                "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
                                "about"
                            ).then(() => {
                                asyncLoadJs(
                                    "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
                                    "about"
                                ).then(() => {
                                    nextTick(() => {
                                        data.newContent.match(/<script.*?>([\s\S]+?)<\/script>/gim);
                                        eval(RegExp.$1);
                                    });
                                });
                            });
                        } else {
                            Toast({
                                type: "error",
                                title: res.data.msg
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        loading.value = false;
                    });
                axios
                    .get("/M/News/ActivityDetailRelation/" + id)
                    .then(res => {
                        if (res.data.success) {
                            const data = res.data.obj;
                            data.listRecommend.forEach((item: any) => {
                                const link = {
                                    name: "ActivityDetail",
                                    query: { id: item.id }
                                };
                                recommendList.push({
                                    id: item.id,
                                    title: item.activityTitle,
                                    link
                                });
                            });
                            likeCount.value = data.likeCount;
                            likeUserCount.value = data.likeUserCount;
                            likeUser.value = data.likeUser;
                            totalCount.value = data.totalCount;
                            listComment.value = data.listComment.map((item: any) => {
                                return {
                                    ...item,
                                    createUser: item.uid,
                                    user: {
                                        userImg: item.userImg,
                                        nickName: item.nickName
                                    }
                                };
                            });
                            previous.value = data.previous;
                            next.value = data.next;
                        } else {
                            Toast({
                                type: "error",
                                title: res.data.msg
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }

            getRenderData(id.value as string);
            const loadOption = {
                text: "加载中...",
                color: "#df0024",
                textColor: "#df0024"
            };
            //  preview picture
            const imgList = reactive<ImgItem[]>([]);
            const currentIndex = ref(0);
            const previewing = ref(false);
            function getSrcIndex(src: string, array: JQuery<HTMLElement>): number {
                let index = 0;
                array.each(function(i, item) {
                    if ($(this).prop("src") == src) {
                        index = i;
                    }
                });
                return index;
            }

            onMounted(() => {
                $(document).on("click", ".news-content img", function(e: Event) {
                    const src = $(this).prop("src");
                    currentIndex.value = getSrcIndex(src, $(".news-content img"));
                    previewing.value = true;
                });
            });
            function closePreview() {
                previewing.value = false;
            }
            watch(
                () => {
                    return shareTitle.value
                },
                (to, from) => {
                    const isweixin = ref(false);
                    const ua = navigator.userAgent.toLowerCase();
                    const reg = /MicroMessenger/i;
                    isweixin.value = reg.test(ua);
                    if (isweixin.value) {
                        nextTick(() => {
                            // const imgUrl = "http://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
                            const link = window.location.href;
                            const title = document.title;
                            const desc = (document.querySelectorAll(
                                "meta[name=description]"
                            )[0] as any).content;
                            console.log(desc);

                            const formData = new FormData();
                            formData.append("url", link);
                            axios.post("/M/Server/getweixin", formData).then((res: any) => {
                                const data = res.data;
                                wx.config({
                                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                    appId: data.appid, // 必填，公众号的唯一标识
                                    timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                                    signature: data.str, // 必填，签名
                                    jsApiList: [
                                        "checkJsApi",
                                        "updateAppMessageShareData",
                                        "updateTimelineShareData",
                                        "onMenuShareTimeline",
                                        "onMenuShareAppMessage"
                                    ], // 必填，需要使用的JS接口列表
                                });
                                wx.ready(function () {
                                    //需在用户可能点击分享按钮前就先调用

                                    wx.updateAppMessageShareData({
                                        title: to+'-北纳生物', // 分享标题
                                        desc: route.meta.content.description, // 分享描述
                                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                        // imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                                        imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg",
                                        success: function () {
                                            // 设置成功
                                        },
                                    });
                                    wx.updateTimelineShareData({
                                        title: to+'-北纳生物', // 分享标题
                                        desc: route.meta.content.description, // 分享描述
                                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                        // imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                                        imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg",
                                        success: function () {
                                            // 设置成功
                                        },
                                    });

                                    wx.onMenuShareTimeline({
                                        title: to+'-北纳生物', // 分享标题
                                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                        imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                                        success: function () {
                                            // 用户点击了分享后执行的回调函数

                                        }
                                    })

                                    wx.onMenuShareAppMessage({
                                        title: to+'-北纳生物', // 分享标题
                                        desc: route.meta.content.description, // 分享描述
                                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                        imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                                        success: function () {
                                            // 用户点击了分享后执行的回调函数
                                        }
                                    })
                                });
                            });
                        });
                    }
                }
            )

            watch(
                () => {
                    return news.value;
                },
                () => {
                    nextTick(() => {
                        const imgDom = document.querySelectorAll(".news-content img");
                        imgDom.forEach(img => {
                            imgList.push({ src: img.getAttribute("src") as string });
                        });
                    });
                }
            );

            watch(
                [
                    () => {
                        return route.query;
                    },
                    () => {
                        return route.params;
                    }
                ],
                (to, from) => {
                    console.log(to, from);
                    // 避免登录时触发页面刷新
                    if (to[0].newsid && !from[1].valid) {
                        getRenderData(to[0].newsid as string);
                    }
                }
            );
            const my = inject("userInfo") as any;
            function onStar() {
                const data = qs.stringify({
                    id: (news.value as any).id,
                    type: 0
                });
                axios
                    .post("/M/Server/DoLikeEntity", data, {
                        params: {
                            validate: true
                        }
                    })
                    .then(res => {
                        if (res.data.success) {
                            Toast({
                                type: "success",
                                title: res.data.msg
                            });
                            (likeUser.value as object[]).unshift(my);
                            likeUserCount.value += 1;
                            likeCount.value = 1;
                            // totalCount.value += 1;
                        } else {
                            Toast({
                                type: "error",
                                title: res.data.msg
                            });
                        }
                    })
                    .catch(err => {
                        const message = err.message ? err.message : "未知错误，请联系管理员";
                        Toast({
                            type: "error",
                            title: message
                        });
                    });
            }
            const comment = ref("");
            function subComment() {
                console.log(comment);
                if (!comment.value) {
                    Toast({
                        type: "warning",
                        title: "请先输入评论再提交哦~"
                    });
                    return false;
                }
                const data = qs.stringify({
                    id: (news.value as any).id,
                    newsCommentContent: comment.value
                });
                axios
                    .post("/M/News/DoNewsComment", data, {
                        params: {
                            validate: true
                        }
                    })
                    .then(res => {
                        if (res.data.success) {
                            Toast({
                                type: "success",
                                title: res.data.msg
                            });
                            (listComment.value as object[]).unshift({
                                ...res.data.obj,
                                isDoLike: 0,
                                content: comment.value,
                                user: {
                                    ...my,
                                    userImg: (my as any).userImg
                                }
                            });
                            totalCount.value = Number(totalCount.value)
                                ? Number(totalCount.value) + 1
                                : 1;
                            comment.value = "";
                        } else {
                            Toast({
                                type: "error",
                                title: res.data.msg
                            });
                        }
                    })
                    .catch(err => {
                        const message = err.message ? err.message : "未知错误，请联系管理员";
                        Toast({
                            type: "error",
                            title: message
                        });
                    });
            }

            function handleApplyGp() {
                console.log(my);
                if (!my.id) {
                    router.push({
                        path: "/login",
                        query: {
                            redirect: "/newsdetail?newsid=15835"
                        }
                    });
                    return false;
                }
                axios
                    .post("/M/Server/isExistAddress")
                    .then(res => {
                        if (res.data.success) {
                            Modal({
                                title: "申请光盘",
                                content: "确定申请北纳生物“微生物菌种与细胞培养实操课程”光盘？",
                                confirmText: "继续申请",
                                closeText: "知道了",
                                onConfirm: () => {
                                    axios
                                        .post("/M/Server/ApplyLightDisk")
                                        .then(res => {
                                            if (res.data.success) {
                                                Toast({
                                                    type: "success",
                                                    title: res.data.msg
                                                });
                                            } else {
                                                Toast({
                                                    type: "error",
                                                    title: res.data.msg
                                                });
                                            }
                                        })
                                        .catch(err => {
                                            const message = err.message
                                                ? err.message
                                                : "未知错误，请联系管理员";
                                            Toast({
                                                type: "error",
                                                title: message
                                            });
                                        });
                                }
                            });
                        } else {
                            Modal({
                                title: "申请光盘",
                                content: "您还没有收货地址，为了方便邮寄，请移步会员中心完善地址",
                                confirmText: "确定",
                                closeText: "取消"
                            });
                        }
                    })
                    .catch(err => {
                        const message = err.message ? err.message : "未知错误，请联系管理员";
                        Toast({
                            type: "error",
                            title: message
                        });
                    });
            }

            function onStarComment(id: string) {
                const data = qs.stringify({
                    id,
                    type: 0
                });
                axios
                    .post("/M/Server/DoLikeComment", data, {
                        params: {
                            validate: true
                        }
                    })
                    .then(res => {
                        if (res.data.success) {
                            Toast({
                                type: "success",
                                title: res.data.msg
                            });
                            for (let i = 0; i < (listComment.value as []).length; i++) {
                                const element = listComment.value[i];
                                if (element.id == id) {
                                    // element.isDoLike = 1;
                                    element.likeCount += 1;
                                }
                            }
                        } else {
                            Toast({
                                type: "error",
                                title: res.data.msg
                            });
                        }
                    })
                    .catch(err => {
                        const message = err.message ? err.message : "未知错误，请联系管理员";
                        Toast({
                            type: "error",
                            title: message
                        });
                    });
            }

            return {
                news,
                recommendList,
                likeUser,
                likeCount,
                likeUserCount,
                totalCount,
                listComment,
                dateFormat,
                loadOption,
                imgList,
                previewing,
                currentIndex,
                loading,
                closePreview,
                onStar,
                comment,
                subComment,
                onStarComment,
                previous,
                next,
                id,
                handleApplyGp
            };
        }
    });
